.editorWrapper {
  border: solid 1px #ced4da;
  margin-top: 1rem;
  background-color: #fff;
  margin-bottom: 1rem;
  min-height: 300px;
}

.editorToolbar {
  border-bottom: solid 1px #ced4da;
}

.editor {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
